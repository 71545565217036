import {Route} from "vue-router";
import {CurrentUserService} from "@/core/services/current-user-service/current-user-service";
import {RawLocation} from "vue-router/types/router";
import {IRouteMetadata, routeMetadata} from "@/router/models/route-metadata";
import {RouteNames} from "@/router/route-names";

export class RequireAuthenticationGuard {
    private static readonly currentUserService = new CurrentUserService();

    public static checkAndProcess(to: Route, from: Route, next: (r?: RawLocation) => void): boolean {
        if (routeMetadata(to.meta).RequireAuthentication) {
            if (!this.currentUserService.isAuthenticated) {
                next({
                    name: RouteNames.Login,
                    query: {
                        returnUrl: window.location.pathname
                    }
                });
                return false;
            }
        }

        next();
        return true;
    }
}
