import {RouteConfig} from "vue-router";
import {RouteNames} from "@/router/route-names";
import {buildRouteMetadata} from "@/router/models/route-metadata";
import {LayoutNames} from "@/core/constants/LayoutNames";
import {UserTypeEnum} from "@/generated/clients";

const administrationRoutes: RouteConfig[] = [
    {
        path: '/administration/moderation-requests',
        name: RouteNames.Administration_ModerationRequests,
        component: () => import(/* webpackChunkName: "administration.moderation-requests" */ "../../views/administration/moderation/BookingObjectModerationRequests.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.SystemAdministrator, UserTypeEnum.SystemManager]
        })
    },{
        path: '/administration/booking-object/removal-requests',
        name: RouteNames.Administration_BookingObjectRemovalRequests,
        component: () => import(/* webpackChunkName: "administration.booking-object.removal-requests" */ "../../views/administration/booking-object-removal-request/BookingObjectRemovalRequests.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.SystemAdministrator, UserTypeEnum.SystemManager]
        })
    }, {
        path: '/administration/review-management/create',
        name: RouteNames.Administration_ReviewManagement_CreateReview,
        component: () => import(/* webpackChunkName: "administration.review-management.create" */ "../../views/administration/review-management/Manage.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.SystemAdministrator]
        })
    }, {
        path: '/administration/review-management/update/:id',
        name: RouteNames.Administration_ReviewManagement_UpdateReview,
        component: () => import(/* webpackChunkName: "administration.review-management.update" */ "../../views/administration/review-management/Manage.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.SystemAdministrator]
        })
    }, {
        path: '/administration/review-management',
        name: RouteNames.Administration_ReviewManagement,
        component: () => import(/* webpackChunkName: "administration.review-management" */ "../../views/administration/review-management/ReviewManagement.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.SystemAdministrator]
        })
    }, {
        path: '/administration/metadata',
        name: RouteNames.Administration_MetadataUploading,
        component: () => import(/* webpackChunkName: "administration.metadata-uploading" */ "../../views/administration/metadata-uploading/MetadataUploading.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.SystemAdministrator]
        })
    }, {
        path: '/administration/metadata/country-management',
        name: RouteNames.Administration_AddressMetadataManagement_CountryManagement,
        component: () => import(/* webpackChunkName: "administration.country-management" */ "../../views/administration/address-metadata-management/country-management/CountryManagement.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.SystemAdministrator]
        })
    }, {
        path: '/administration/metadata/country-region-management',
        name: RouteNames.Administration_AddressMetadataManagement_CountryRegionManagement,
        component: () => import(/* webpackChunkName: "administration.country-region-management" */ "../../views/administration/address-metadata-management/country-region-management/CountryRegionManagement.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.SystemAdministrator]
        })
    }, {
        path: '/administration/metadata/country-region-city-management',
        name: RouteNames.Administration_AddressMetadataManagement_CountryRegionCityManagement,
        component: () => import(/* webpackChunkName: "administration.country-region-city-management" */ "../../views/administration/address-metadata-management/country-region-city-management/CountryRegionCityManagement.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.SystemAdministrator]
        })
    },
    {
        path: '/administration/business-model-management',
        name: RouteNames.Administration_BusinessModelManagement,
        component: () => import(/* webpackChunkName: "administration.business-model-management" */ "../../views/administration/business-model-management/BusinessModelManagement.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.SystemAdministrator]
        })
    },

    {
        path: '/administration/marketing/mass-mailings',
        name: RouteNames.Administration_Marketing_MassMailings,
        component: () => import(/* webpackChunkName: "administration.mass-mailings" */ "../../views/administration/mass-mailings/MassMailings.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.SystemAdministrator]
        })
    },
    {
        path: '/administration/marketing/mass-mailings/create',
        name: RouteNames.Administration_Marketing_MassMailings_Create,
        component: () => import(/* webpackChunkName: "administration.mass-mailings.create" */ "../../views/administration/mass-mailings/CreateMailing.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.SystemAdministrator]
        })
    },
    {
        path: '/administration/exchange-rates',
        name: RouteNames.Administration_ExchangeRates,
        component: () => import(/* webpackChunkName: "administration.exchange-rates" */ "../../views/administration/exchange-rates/ExchangeRates.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.SystemAdministrator]
        })
    },
    {
        path: '/administration/dashboard',
        name: RouteNames.Administration_Dashboard,
        component: () => import(/* webpackChunkName: "administration.dashboard" */ "../../views/administration/dashboard/Dashboard.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.SystemAdministrator]
        })
    }, {
        path: '/administration/booking-management',
        name: RouteNames.Administration_BookingManagement,
        component: () => import(/* webpackChunkName: "administration.booking-management" */ "../../views/administration/booking-management/BookingManagement.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.SystemAdministrator]
        })
    }, {
        path: '/administration/occupancy-synchronization-monitoring',
        name: RouteNames.Administration_OccupancySynchronizationMonitoring,
        component: () => import(/* webpackChunkName: "administration." */ "../../views/administration/occupancy-synchronization-monitoring/OccupancySynchronizationMonitoring.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.SystemAdministrator]
        })
    }, {
        path: '/administration/user-management',
        name: RouteNames.Administration_UserManagement,
        component: () => import(/* webpackChunkName: "administration.user-management" */ "../../views/administration/user-management/UserManagement.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.SystemAdministrator]
        })
    }, {
        path: '/administration/content-management/article-management',
        name: RouteNames.Administration_ContentManagement_ArticleManagement,
        component: () => import(/* webpackChunkName: "administration.content-management.article-management" */ "../../views/administration/content-management.article-management/ArticleManagement.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.SystemAdministrator]
        })
    }, {
        path: '/administration/content-management/article-management/create',
        name: RouteNames.Administration_ContentManagement_ArticleManagement_Create,
        component: () => import(/* webpackChunkName: "administration.content-management.article-management.create" */ "../../views/administration/content-management.article-management/ManageArticle.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.SystemAdministrator]
        })
    }, {
        path: '/administration/content-management/article-management/update/:id',
        name: RouteNames.Administration_ContentManagement_ArticleManagement_Update,
        component: () => import(/* webpackChunkName: "administration.content-management.article-management.update" */ "../../views/administration/content-management.article-management/ManageArticle.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.SystemAdministrator]
        })
    }, {
        path: '/administration/manual-task-scheduling',
        name: RouteNames.Administration_ManualTaskScheduling,
        component: () => import(/* webpackChunkName: "administration.manual-task-scheduling" */ "../../views/administration/manual-task-scheduling/ManualTaskScheduling.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.SystemAdministrator]
        })
    }
];

export default administrationRoutes;
