import {ConversationParticipantBM} from "@/core/models/conversation/ConversationParticipantBM";
import {buildDefault} from "@/core/utils/functional-utils";
import {Guid} from "@/core/types/type-aliases";
import {Defaults} from "@/core/constants/defaults";

export class TemplateDataProvider {

    public static buildServiceMessageParticipantData(): ConversationParticipantBM {
        return new ConversationParticipantBM(
            buildDefault<Guid>(),
            'Виртуальный помощник БРОНИ24',
            Defaults.EmptyString,
            'https://storage.yandexcloud.net/brony24-public-assets/service-message-participant.png',
            true
        );
    }
}
