import {CurrencyVDTO} from "@/generated/clients";

export class CurrencyBM {
    public constructor(
        id: number,
        name: string,
        charCode: string
    ) {
        this.Id = id;
        this.Name = name;
        this.CharCode = charCode;
    }

    public Id: number;
    public Name: string;
    public CharCode: string;

    public static createFromDTO(dto: CurrencyVDTO): CurrencyBM {
        return new CurrencyBM(
            dto.Id!,
            dto.Name!,
            dto.CharCode!
        );
    }
}
