import {ConversationBM} from "@/core/models/conversation/ConversationBM";
import {ConversationSummaryForUserVDTO} from "@/generated/clients";

export class ConversationSummaryForUserBM {
    public constructor(conversation: ConversationBM, unreadMessagesCount: number) {
        this.Conversation = conversation;
        this.UnreadMessagesCount = unreadMessagesCount;
    }

    public Conversation: ConversationBM;
    public UnreadMessagesCount: number;

    public static createFromDTO(dto: ConversationSummaryForUserVDTO): ConversationSummaryForUserBM {
        return new ConversationSummaryForUserBM(
            ConversationBM.createFromDTO(dto.Conversation!),
            dto.UnreadMessagesCount!
        );
    }
}
