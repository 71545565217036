import {LanguageReadDTO} from "@/generated/clients";

export class LanguageBM {
    public constructor(id: number, name: string) {
        this.Id = id;
        this.Name = name;
    }

    public Id: number;
    public Name: string;

    public static createFromDTO(dto: LanguageReadDTO): LanguageBM {
        return new LanguageBM(dto.Id!, dto.Name!);
    }
}
