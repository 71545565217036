export class ApplicationEnvironmentService {
    private static readonly developmentEnvironmentNames = ['development'];
    private static readonly productionEnvironmentNames = ['staging', 'production'];

    public static get isDevelopment(): boolean {
        return ApplicationEnvironmentService.developmentEnvironmentNames.includes(process.env.NODE_ENV);
    }

    public static get isProduction(): boolean {
        return ApplicationEnvironmentService.productionEnvironmentNames.includes(process.env.NODE_ENV);
    }
}
