import {Defaults} from "@/core/constants/defaults";

export function buildDefault<T = any>(): T {
    return null as any as T;
}

export function cast<T = any>(data: any): T {
    return data as T;
}

export function simulateDelay(timeout: number): Promise<void> {
    return new Promise<void>(resolve => {
        setTimeout(resolve, timeout)
    });
}

export function simulateDeepCopy<T>(data: T): T {
    return JSON.parse(JSON.stringify(data)) as T;
}

export function stripHtml(rawHtml: string): string {
    const domDocument = new DOMParser().parseFromString(rawHtml, 'text/html');
    return domDocument.body.textContent || Defaults.EmptyString;
}

export function randomInRange(start: number, finish: number) {
    return Math.floor(Math.random() * (finish - start + 1) + start);
}

export interface Action<T> {
    (item: T): any;
}

export interface Callback {
    (): void;
}

export interface CallbackAsync {
    (): Promise<void>;
}

export interface FuncWithoutArgs<TResult> {
    (): TResult;
}

export interface Func<T, TResult> {
    (item: T): TResult;
}

export function isNumeric(n: string | number) {
    return !isNaN(parseFloat(n as any as string)) && isFinite(n as any as number);
}
