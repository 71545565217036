import OccupancySynchronizationMonitoring from "@/views/administration/occupancy-synchronization-monitoring/OccupancySynchronizationMonitoring.vue";
import ManualTaskScheduling from "@/views/administration/manual-task-scheduling/ManualTaskScheduling.vue";

export class RouteNames {
    public static readonly Home = "Home";
    public static readonly About = "About";
    public static readonly Cookies = "Cookies";
    public static readonly Help = "Help";
    public static readonly Privacy = "Privacy";
    public static readonly Terms = "Terms";

    public static readonly Login = "Login";
    public static readonly Register = "Register";
    public static readonly ChangePassword = "ChangePassword";
    public static readonly SendRestorePasswordApplication = "SendRestorePasswordApplication";
    public static readonly ResetPassword = "ResetPassword";
    public static readonly ConfirmEmail = "ConfirmEmail";

    public static readonly MyProfile = "MyProfile";
    public static readonly UserProfile = 'UserProfile';

    public static readonly MyBookingObjects = "MyBookingObjects";

    public static readonly Create_BookingObject_Apartments = "Create_BookingObject_Apartments";
    public static readonly Create_BookingObject_Apartments_FromDraft = "Create_BookingObject_Apartments_FromDraft";
    public static readonly Update_BookingObject_Apartments = "Update_BookingObject_Apartments";

    public static readonly Create_BookingObject_Hotels = "Create_BookingObject_Hotels";
    public static readonly Create_BookingObject_Hotels_FromDraft = "Create_BookingObject_Hotels_FromDraft";
    public static readonly Update_BookingObject_Hotels = "Update_BookingObject_Hotels";

    public static readonly Create_BookingObject_Houses = "Create_BookingObject_Houses";
    public static readonly Create_BookingObject_Houses_FromDraft = "Create_BookingObject_Houses_FromDraft";
    public static readonly Update_BookingObject_Houses = "Update_BookingObject_Houses";

    public static readonly BookingObjectSearch = "BookingObjectSearch"
    public static readonly BookingObjectView = "BookingObjectView";

    public static readonly BookingObjectListByUser = "BookingObjectListByUser";

    public static readonly BookingObjectManagement_OccupancySchedule = 'BookingObjectManagement_OccupancySchedule';
    public static readonly BookingObjectManagement_OccupancySchedule_ForUser = 'BookingObjectManagement_OccupancySchedule_ForUser';
    public static readonly BookingObjectManagement_OccupancySchedule_ForBedding = 'BookingObjectManagement_OccupancySchedule_ForBedding';
    public static readonly BookingObjectManagement_OccupancySchedule_ForApartment = 'BookingObjectManagement_OccupancySchedule_ForApartment';

    public static readonly BookingObjectManagement_CalendarsManagement = 'BookingObjectManagement_CalendarsManagement';

    public static readonly BookingsForServiceProvider = "BookingsForServiceProvider";
    public static readonly BookingsForServiceConsumer = "BookingsForServiceConsumer";

    public static readonly Administration_ModerationRequests = 'Administration_ModerationRequests';
    public static readonly Administration_MetadataUploading = 'Administration_MetadataUploading';

    public static readonly Administration_ReviewManagement_CreateReview = 'Administration_ReviewManagement_CreateReview';
    public static readonly Administration_ReviewManagement_UpdateReview = 'Administration_ReviewManagement_UpdateReview';
    public static readonly Administration_ReviewManagement = 'Administration_ReviewManagement';

    public static readonly Administration_AddressMetadataManagement_CountryManagement =
        'Administration_AddressMetadataManagement_CountryManagement';

    public static readonly Administration_AddressMetadataManagement_CountryRegionManagement =
        'Administration_AddressMetadataManagement_CountryRegionManagement';

    public static readonly Administration_AddressMetadataManagement_CountryRegionCityManagement =
        'Administration_AddressMetadataManagement_CountryRegionCityManagement';

    public static readonly PageNotFound = "PageNotFound";
    public static readonly UnauthorizedRequest = "UnauthorizedRequest";

    public static readonly Billing_CheckPayment = 'Billing_CheckPayment';
    public static readonly Billing_UnsuccessfulPayment = 'Billing_UnsuccessfulPayment';

    public static readonly BookingObjectWizard_BaseStep = 'BookingObjectWizard_BaseStep';

    public static readonly Favorites = 'Favorites';

    public static readonly Administration_BusinessModelManagement = 'Administration_BusinessModelManagement';

    public static readonly Administration_Marketing_MassMailings =
        'Administration_Marketing_MassMailings';

    public static readonly Administration_Marketing_MassMailings_List =
        'Administration_Marketing_MassMailings_List';

    public static readonly Administration_Marketing_MassMailings_Create =
        'Administration_Marketing_MassMailings_Create';

    public static readonly Administration_ExchangeRates = 'Administration_ExchangeRates';
    public static readonly Administration_Dashboard = 'Administration_Dashboard';

    public static readonly ConversationManager = 'ConversationManager';

    public static readonly Rating_CreateClientBookingObjectReview = 'Rating_CreateClientBookingObjectReview';

    public static readonly KnowledgeBase_HowToRegister = 'KnowledgeBase_HowToRegister';
    public static readonly KnowledgeBase_HowToInstallMobileApplication = 'KnowledgeBase_HowToInstallMobileApplication';
    public static readonly KnowledgeBase_BookingTerms = 'KnowledgeBase_BookingTerms';
    public static readonly KnowledgeBase_BookingObjectRegistration = 'KnowledgeBase_BookingObjectRegistration';
    public static readonly KnowledgeBase_BookingObjectPricePolicies = 'KnowledgeBase_BookingObjectPricePolicies';
    public static readonly KnowledgeBase_BookingObjectOccupancySynchronization = 'KnowledgeBase_BookingObjectOccupancySynchronization';

    public static readonly Administration_BookingManagement = 'Administration_BookingManagement';
    public static readonly Administration_BookingObjectRemovalRequests = 'Administration_BookingObjectRemovalRequests';
    public static readonly Administration_OccupancySynchronizationMonitoring = 'Administration_OccupancySynchronizationMonitoring';

    public static readonly Administration_UserManagement = 'Administration_UserManagement';

    public static readonly Administration_ContentManagement = 'Administration_ContentManagement';
    public static readonly Administration_ContentManagement_ArticleManagement = 'Administration_ContentManagement_ArticleManagement';
    public static readonly Administration_ContentManagement_ArticleManagement_Create = 'Administration_ContentManagement_ArticleManagement_Create';
    public static readonly Administration_ContentManagement_ArticleManagement_Update = 'Administration_ContentManagement_ArticleManagement_Update';

    public static readonly Administration_ManualTaskScheduling = 'Administration_ManualTaskScheduling';

    public static readonly CMS_ArticleHandler = 'CMS_ArticleHandler';
}
