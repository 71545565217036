import {EmptyControllerActionResult} from "@/generated/clients";

export class EmptyOperationResultBM {
    public constructor(ok: boolean, errorMessages: string[]) {
        this.Ok = ok;
        this.ErrorMessages = errorMessages;
    }

    public Ok: boolean;
    public ErrorMessages: string[];

    public get HasFailed(): boolean {
        return !this.Ok;
    }

    public static success(): EmptyOperationResultBM {
        return new EmptyOperationResultBM(true, []);
    }

    public static createFromDTO(dto: EmptyControllerActionResult): EmptyOperationResultBM {
        return new EmptyOperationResultBM(
            dto.Ok as boolean,
            dto.ErrorMessages as string[]
        );
    }
}
