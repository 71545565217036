import {ConversationMessageBM} from "@/core/models/conversation/ConversationMessageBM";
import {ApiClientProvider} from "@/core/providers/api-client-provider";
import {
    ConversationClient, MarkConversationMessagesAsReadForUserRequestDTO,
    ReadConversationMessagesPartitionedRequestDTO,
    ReadConversationSummariesForUserRequestDTO,
    SendMessageRequestDTO
} from "@/generated/clients";
import {Guid} from "@/core/types/type-aliases";
import {ConversationSummaryForUserBM} from "@/core/models/conversation/ConversationSummaryForUserBM";
import {EmptyOperationResultBM} from "@/core/models/common/EmptyOperationResultBM";
import {OperationResultBM} from "@/core/models/common/OperationResultBM";
import {PartitioningOptionsBM} from "@/core/models/common/PartitioningOptionsBM";
import {ConversationMessagePartitioningFeaturesVO} from "@/core/value-objects/ConversationMessagePartitioningFeaturesVO";
import {buildDefault} from "@/core/utils/functional-utils";

export class ConversationService {
    private readonly client = ApiClientProvider.provide(ConversationClient);

    public async readConversations(): Promise<ConversationSummaryForUserBM[]> {
        const request: ReadConversationSummariesForUserRequestDTO = {};
        const response = await this.client.summariesForUser(request);
        return response.map(ConversationSummaryForUserBM.createFromDTO);
    }

    public async readConversationMessagesPartitioned(
        conversationId: Guid, partitioningOptions: PartitioningOptionsBM<ConversationMessagePartitioningFeaturesVO>)
        : Promise<ConversationMessageBM[]> {

        const request: ReadConversationMessagesPartitionedRequestDTO = {
            ConversationId: conversationId,
            PartitioningOptions: {
                Size: partitioningOptions.Size,
                Features: {
                    ConversationMessageId: partitioningOptions.Features.ConversationMessageId
                }
            }
        };

        const response = await this.client.readConversationMessagesPartitioned(request);
        return response.map(ConversationMessageBM.createFromDTO);
    }

    public async sendMessage(conversationId: Guid, messageContent: string)
        : Promise<OperationResultBM<ConversationMessageBM>> {
        const request: SendMessageRequestDTO = {ConversationId: conversationId, MessageContent: messageContent};
        const response = await this.client.sendMessage(request);

        const result = response.Result != null
            ? ConversationMessageBM.createFromDTO(response.Result!)
            : buildDefault<ConversationMessageBM>();

        return new OperationResultBM<ConversationMessageBM>(
            response.Ok!,
            response.ErrorMessages!,
            result
        );
    }

    public async markConversationMessagesAsRead(conversationId: Guid, conversationMessageIds: Guid[])
        : Promise<EmptyOperationResultBM> {
        const request: MarkConversationMessagesAsReadForUserRequestDTO = {
            ConversationId: conversationId,
            ConversationMessageIds: conversationMessageIds
        };

        const response = await this.client.markMessagesAsRead(request);
        return EmptyOperationResultBM.createFromDTO(response);
    }

    public async readUnreadConversationMessagesCount(): Promise<number> {
        return await this.client.unreadConversationMessagesCount({});
    }

    public async readBookingRelatedConversationId(bookingId: Guid): Promise<Guid> {
        return await this.client.readBookingRelatedConversationId({BookingId: bookingId});
    }
}
