export class AudioPlayingService {
    public async notificationAlert() {
        try {
            const audio = new Audio(require('../../assets/sounds/notification-alert-v2.wav'));
            await audio.play();
        } catch {
            // Ignore if user declined audio or not interacted on the page:
            // nothing to do.
        }
    }

}
