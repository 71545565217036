import {AxiosInstanceProvider} from "@/core/providers/axios-instance-provider";
import {MetadataClient} from "@/generated/clients";
import {BaseUrlProvider} from "@/core/providers/base-url-provider";
import {CountryBM} from "@/core/models/metadata/CountryBM";
import {CurrencyBM} from "@/core/models/metadata/CurrencyBM";
import {TimezoneBM} from "@/core/models/metadata/TimezoneBM";
import {LanguageBM} from "@/core/models/metadata/LanguageBM";

export class MetadataService {
    private readonly client = new MetadataClient(BaseUrlProvider.getBaseUrl(), AxiosInstanceProvider.Get());

    private static countriesCached: CountryBM[] = [];
    private static timezonesCached: TimezoneBM[] = [];
    private static languagesCached: LanguageBM[] = []
    private static currenciesMapCached: Map<number, CurrencyBM> = new Map<number, CurrencyBM>()

    public static get currenciesMap() {
        return MetadataService.currenciesMapCached;
    }

    public static get timezonesMap(): Map<number, TimezoneBM> {
        const map = new Map<number, TimezoneBM>();
        for (const timezone of MetadataService.timezonesCached) {
            map.set(timezone.Id, timezone);
        }

        return map;
    }

    public static get timezones(): TimezoneBM[] {
        return this.timezonesCached;
    }

    public static get countries(): CountryBM[] {
        return this.countriesCached;
    }

    public static get languages(): LanguageBM[] {
        return this.languagesCached;
    }

    public async ReadCountries(): Promise<CountryBM[]> {
        if (MetadataService.countriesCached.length === 0) {
            const countryDTOs = await this.client.countries();
            MetadataService.countriesCached = countryDTOs.map(c => CountryBM.createFromDTO(c));
        }

        return MetadataService.countriesCached;
    }

    public async ReadCurrencies(): Promise<Map<number, CurrencyBM>> {
        if (MetadataService.currenciesMapCached.size === 0) {
            const currencyDTOs = await this.client.currencies();
            for (const currencyDTO of currencyDTOs) {
                MetadataService.currenciesMapCached.set(currencyDTO.Id!,
                    CurrencyBM.createFromDTO(currencyDTO));
            }
        }

        return MetadataService.currenciesMapCached;
    }

    public async ReadTimezones(): Promise<TimezoneBM[]> {
        if (MetadataService.timezonesCached.length === 0) {
            const timezones = (await this.client.timezones()).map(t => TimezoneBM.createFromDTO(t));
            MetadataService.timezonesCached = timezones;
        }

        return MetadataService.timezonesCached;
    }

    public async ReadLanguages(): Promise<LanguageBM[]> {
        if (MetadataService.languagesCached.length === 0) {
            const languages = (await this.client.languages()).map(l => LanguageBM.createFromDTO(l));
            MetadataService.languagesCached = languages;
        }

        return MetadataService.languagesCached;
    }

    public static clearAddressMetadataCache() {
        this.countriesCached = [];
    }
}
