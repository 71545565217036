import {Observable, Subject} from "rxjs";
import {InformationModalData} from "@/core/components/InformationModal/models/information-modal-data";

export class InformationMessagesService {
    private static $eventSource = new Subject<InformationModalData>();
    private static $observer = InformationMessagesService.$eventSource.asObservable();

    public Push(data: InformationModalData): void {
        InformationMessagesService.$eventSource.next(data);
    }

    public get Observer(): Observable<InformationModalData> {
        return InformationMessagesService.$observer;
    }
}
