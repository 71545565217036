import {DeviceChecker} from "@/helpers/device-checker";
import {Action} from "@/core/utils/functional-utils";

export class ScrollHelper {
    public static scrollToTop() {
        window.scrollTo({top: 0, behavior: "smooth"});
        if (DeviceChecker.isMobile()) {
            this.scrollBaseViewToTop();
        }
    }

    public static scrollBaseViewToTop() {
        const container = document.getElementsByClassName('base-view')[0];
        if (container) {
            container.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    public static getScrollPosition(): number {
        if (DeviceChecker.isMobile()) {
            return document.getElementsByClassName('base-view')[0].scrollTop;
        } else {
            return document.documentElement.scrollTop;
        }
    }

    public static setScrollPosition(pos: number) {
        if (DeviceChecker.isMobile()) {
            document.getElementsByClassName('base-view')[0].scrollTo({top: pos, behavior: "smooth"});
        } else {
            window.scrollTo({top: pos, behavior: "smooth"});
        }
    }

    public static scrollToElementIntoContainer(container: HTMLElement, element: HTMLElement, offset = 0) {
        container.scrollTo({top: element.offsetTop + offset, behavior: 'smooth'});
    }

    public static subscribeOnScroll(callback: Action<number>) {
        console.log(ScrollHelper.getScrollPosition());

        document.addEventListener("scroll", event => {
            callback(this.getScrollPosition());
        });

        document.getElementsByClassName('base-view')[0].addEventListener('scroll', event => {
            callback(this.getScrollPosition());
        });
    }
}
