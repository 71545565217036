<template>
    <div id="app">
        <b-overlay opacity="0.85" variant="white"
                   v-bind:show="globalOverlayOptions.IsOverlayVisible"
                   v-bind:class="{'preloading': isApplicationPreloading}"
                   no-wrap fixed>
            <template #overlay>
                <div class="text-center">
                    <span aria-hidden="true" class="spinner-border"></span>

                    <template v-if="isApplicationPreloading">
                        <div class="preloading-app-name d-block d-sm-none">БРОНИ24</div>

                        <img class="img-fluid global-loading-logo d-none d-sm-block"
                             src="../src/assets/logo-v2/logo.webp"
                             loading="lazy">

                    </template>

                    <div v-if="globalOverlayOptions.Text" class="spinner-content" v-html="globalOverlayOptions.Text"></div>
                </div>
            </template>
        </b-overlay>

        <template v-if="isComponentMounted">
            <component v-bind:is="layoutComponentName" v-bind="layoutProperties">
                <router-view v-bind:key="$route.fullPath"/>
            </component>
        </template>

        <information-modal ref="informationModal" v-bind:data="informationModalData"/>

        <b-alert v-model="showCookiesNotification" class="position-fixed fixed-bottom m-0 rounded-0 cookie-alert">
            <div class="cookie-popup">
                <div class="container">
                    <div class="text">
                        <b class="main-text">Платформа БРОНИ24 использует файлы cookie</b><br/>
                        Нажимая на кнопку <b>«Принять»</b>, Вы соглашаетесь с тем, что ознакомились с
                        <a href="https://storage.yandexcloud.net/brony24-public-docs/%D0%9F%D0%98%D0%9A%D0%A4.pdf">
                            политикой использования файлов cookie
                        </a>
                        и больше не хотели бы её видеть. Для этого браузер сохранит на вашем устройстве
                        соответствующюю информацию. Чтобы снова увидеть данную информацию — очистите данные сайта или откройте его в
                        на другом устройстве.
                    </div>
                    <div class="management">
                        <button class="btn btn-input" @click="acceptCookiePolicy">Принять</button>
                    </div>
                </div>
            </div>
        </b-alert>
    </div>
</template>

<script lang="ts">
import {Component, Ref, Vue} from "vue-property-decorator";
import {CurrentUserService} from "@/core/services/current-user-service/current-user-service";
import {GlobalOverlayService} from "@/core/services.ui/global-overlay-service";
import {GlobalOverlayOptions} from "@/core/models/system/global-overlay-options";
import {IRouteMetadata} from "@/router/models/route-metadata";
import InformationModal from "@/core/components/InformationModal/InformationModal.vue";
import {InformationMessagesService} from "@/core/services.ui/information-messages-service";
import {InformationModalData} from "@/core/components/InformationModal/models/information-modal-data";
import {LocalizationService} from "./core/services/localization-service";
import {LayoutNames} from "./core/constants/LayoutNames";
import {ILayoutProperties} from "./core/models/common/ILayoutProperties";
import {UserLayoutProperties} from "./layouts/user-layout/models/UserLayoutProperties";
import {CookiePolicyService} from "@/core/services/cookie-policy-service";
import {MetadataService} from "@/core/services/metadata-service";
import {LayoutService} from "@/core/services.ui/layout-service";
import {ClientCurrencyProvider} from "@/core/services/client-currency-provider";
import {ClientCurrencyOptions, ClientCurrencySynchronizationService} from "@/core/services.ui/client-currency-synchronization-service";
import {ExchangeRatesService} from "@/core/services/exchange-rates-service";
import {ConversationSocket, OnConversationCreatedEvent, OnConversationUpdatedEvent} from "@/core/services/conversation/conversation-socket";
import {AudioPlayingService} from "@/core/services/audio-playing-service";
import {UnreadConversationMessagesCountSynchronizationService} from "@/core/services/conversation/unread-conversation-messages-count-synchronization-service";
import NewConversationMessagePopup from "@/views/conversations/components.popup/NewConversationMessagePopup.vue";
import NewConversationPopup from "@/views/conversations/components.popup/NewConversationPopup.vue";
import {DeviceChecker} from "@/helpers/device-checker";
import {BAlert, BOverlay} from "bootstrap-vue";
import {ScrollHelper} from "@/helpers/scroll-helper";
import {QueryStringService} from "@/core/services.ui/query-string-service";
import {ScrollTrackingService} from "@/core/services.ui/scroll-tracking-service";
import {simulateDelay} from "@/core/utils/functional-utils";

@Component({
    components: {
        'b-alert': BAlert,
        'b-overlay': BOverlay,
        'information-modal': InformationModal,
        [LayoutNames.UserLayout]: () => import('@/layouts/user-layout/UserLayout.vue'),
        [LayoutNames.UnauthorizedLayout]: () => import('@/layouts/unauthorized-layout/UnauthorizedLayout.vue')
    }
})
export default class App extends Vue {
    // Component references:
    @Ref("informationModal") informationModal!: InformationModal;

    // Component services:
    private readonly currentUserService = new CurrentUserService();
    private readonly localizationService = new LocalizationService();
    private readonly globalOverlayService = new GlobalOverlayService();
    private readonly informationMessagesService = new InformationMessagesService();
    private readonly cookiePolicyService = new CookiePolicyService();
    private readonly layoutService = new LayoutService();
    private readonly clientCurrencyProvider = new ClientCurrencyProvider();
    private readonly audioPlayingService = new AudioPlayingService();
    private readonly metadataService = new MetadataService();

    // Component data:
    private globalOverlayOptions = new GlobalOverlayOptions(false);
    private informationModalData = InformationModalData.createEmpty();
    private layoutProperties: ILayoutProperties | null = null;

    private showCookiesNotification = true;

    private isComponentMounted = false;
    private isApplicationPreloading = true;

    public async created(): Promise<void> {
        console.log(process.env.VUE_APP_I18N_LOCALE)

        document.documentElement.style.setProperty('--doc-height', `${window.innerHeight}px`);

        this.localizationService.Setup();
        this.layoutService.Slim();
        this.showCookiesNotification = !this.cookiePolicyService.isAccepted();

        // Subscribe on overlay events:
        this.globalOverlayService.Observer.subscribe(options => {
            if (this.globalOverlayOptions.IsOverlayVisible !== options.IsOverlayVisible) {
                this.globalOverlayOptions.IsOverlayVisible = options.IsOverlayVisible;
            }

            if (this.globalOverlayOptions.Text !== options.Text) {
                this.globalOverlayOptions.Text = options.Text;
            }
        });

        // Subscribe on pushing message events:
        this.informationMessagesService.Observer.subscribe(value => {
            this.informationModalData = value;
            this.informationModal!.show();
        });
    }

    public async mounted(): Promise<void> {
        this.globalOverlayService.Loading('Инициализация платформы');

        document.documentElement.style.setProperty('--doc-height', `${window.innerHeight}px`);

        await this.warmUpCache();
        await this.setupClientCurrency();

        await this.setupConversations();

        await this.$nextTick(() => {
            this.isComponentMounted = true;
            this.$nextTick(() => {
                this.isApplicationPreloading = false;
                this.globalOverlayService.Completed();
            });
        });
    }

    private async setupConversations() {
        if (this.currentUserService.isAuthenticated) {
            await UnreadConversationMessagesCountSynchronizationService.initialize();
            await ConversationSocket.initialize();
        }

        ConversationSocket.subscribeOnConversationCreate(event => {
            this.showConversationCreatedNotification(event);

            // Temporary disabled:
            // this.audioPlayingService.notificationAlert();
        })

        ConversationSocket.subscribeOnConversationUpdate(options => {
            UnreadConversationMessagesCountSynchronizationService.increase(1);
            this.showConversationUpdatedNotification(options);

            // Temporary disabled:
            // this.audioPlayingService.notificationAlert();
        });
    }

    private async setupClientCurrency() {
        const clientCurrency = await this.clientCurrencyProvider.readOrDetect();
        ClientCurrencySynchronizationService.synchronize(new ClientCurrencyOptions(clientCurrency));
    }

    private get layoutComponentName(): string {
        const routeMetadata: IRouteMetadata = this.$route.meta as IRouteMetadata;
        const layout = routeMetadata.LayoutComponentName;

        this.layoutProperties = null;

        if (this.currentUserService.isAuthenticated) {
            this.layoutProperties = new UserLayoutProperties();
            return LayoutNames.UserLayout;
        }

        return LayoutNames.UnauthorizedLayout;
    }

    private acceptCookiePolicy() {
        this.cookiePolicyService.accept();
        this.showCookiesNotification = false;
    }

    private async warmUpCache() {
        const metadataLoadingTasks = [
            this.metadataService.ReadCountries(),
            this.metadataService.ReadTimezones(),
            this.metadataService.ReadLanguages(),
            this.metadataService.ReadCurrencies()
        ];

        await Promise.all(metadataLoadingTasks);

        await ExchangeRatesService.initialize();
    }

    private async showConversationCreatedNotification(event: OnConversationCreatedEvent) {
        const position = DeviceChecker.isMobile() ? 'b-toaster-bottom-right' : 'b-toaster-top-right';
        this.$bvToast.toast(NewConversationPopup.$liveCreate(this, event), {
            id: `conversation-popup-${event.Conversation.Id}`,
            toastClass: 'new-conversation-popup-container',
            title: `Уведомление`,
            autoHideDelay: 5000,
            appendToast: true,
            toaster: position
        });
    }

    private async showConversationUpdatedNotification(event: OnConversationUpdatedEvent) {
        const position = DeviceChecker.isMobile() ? 'b-toaster-bottom-right' : 'b-toaster-top-right';
        this.$bvToast.toast(NewConversationMessagePopup.$liveCreate(this, event), {
            id: `conversation-message-popup-${event.ConversationMessage.Id}`,
            toastClass: 'new-conversation-message-popup-container',
            title: `Бронирование №${event.SubjectSummary!.BookingNumber}`,
            autoHideDelay: 5000,
            appendToast: true,
            toaster: position
        });
    }
}
</script>

<style lang="scss">
.spinner-border {
    width: 3rem;
    height: 3rem;
}
</style>
