<template>
    <div class="avatar-component">
        <div class="avatar-container">
            <div class="avatar" v-bind:class="{ 'with-photo': isAvatarSet }" @mouseover="onMouseOver" @mouseleave="onMouseLeave">
                <img v-if="isAvatarSet" v-bind:src="avatarUrl"/>
                <template v-else>{{ avatarAsText }}</template>

                <div v-if="withHint" v-show="isHintShown" class="hint" @click="onAvatarClick">
                    Изменить
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {BaseComponent} from "../components/BaseComponent";
import {Component, Prop} from "vue-property-decorator";
import {StringHelper} from "@/helpers/string-helper";
import {Defaults} from "@/core/constants/defaults";

@Component
export default class Avatar extends BaseComponent {
    @Prop({required: true, type: String}) firstName!: string;
    @Prop({required: true, type: String}) lastName!: string;
    @Prop({required: false, type: String}) avatarUrl!: string;
    @Prop({required: false, type: Boolean, default: false}) withHint!: boolean;

    private isHintShown = false;

    private get isAvatarSet(): boolean {
        return this.avatarUrl != null
    }

    private get avatarAsText(): string {
        if (!StringHelper.IsNotNullOrEmpty(this.firstName) || !StringHelper.IsNotNullOrEmpty(this.lastName)) return Defaults.EmptyString;

        const letter1 = (this.firstName as string)[0];
        const letter2 = (this.lastName as string)[0];
        return `${letter1}${letter2}`;
    }

    private onMouseOver() {
        this.isHintShown = this.withHint && true;
    }

    private onMouseLeave() {
        this.isHintShown = this.withHint && false;
    }

    private onAvatarClick() {
        this.$emit('changeAvatar');
    }
}
</script>
