import {ConversationMessageTypeEnum, ConversationMessageVDTO} from "@/generated/clients";
import {Guid} from "@/core/types/type-aliases";

export class ConversationMessageBM {
    public constructor(
        id: Guid,
        conversationId: Guid,
        senderUserId: Guid | null,
        dateTimeCreated: Date,
        dateTimeUpdated: Date,
        messageContent: string,
        readForUserIds: Guid[],
        type: ConversationMessageTypeEnum) {
        this.Id = id;
        this.ConversationId = conversationId;
        this.SenderUserId = senderUserId;
        this.DateTimeCreated = dateTimeCreated;
        this.DateTimeUpdated = dateTimeUpdated;
        this.MessageContent = messageContent;
        this.ReadForUserIds = readForUserIds;
        this.Type = type;
    }

    public Id: Guid;
    public ConversationId: Guid;
    public SenderUserId: Guid | null;
    public DateTimeCreated: Date;
    public DateTimeUpdated: Date;
    public MessageContent: string;
    public ReadForUserIds: Guid[];
    public Type: ConversationMessageTypeEnum;

    public isUnreadForUser(userId: Guid): boolean {
        return this.SenderUserId !== userId && !this.ReadForUserIds.includes(userId);
    }

    public get isUserMessage(): boolean {
        return this.Type === ConversationMessageTypeEnum.FromUser;
    }

    public get isSystemMessage(): boolean {
        return this.Type === ConversationMessageTypeEnum.FromSystem;
    }

    public static createFromDTO(dto: ConversationMessageVDTO): ConversationMessageBM {
        return new ConversationMessageBM(
            dto.Id!,
            dto.ConversationId!,
            dto.SenderUserId!,
            new Date(dto.DateTimeCreated!),
            new Date(dto.DateTimeUpdated!),
            dto.MessageContent!,
            dto.ReadForUserIds!,
            dto.Type!
        );
    }
}
