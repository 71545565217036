import {UserTypeEnum} from "@/generated/clients";

export interface IRouteMetadata {
    LayoutComponentName: string;
    RequireAuthentication?: boolean;
    RequireRoles?: UserTypeEnum[];
    Options?: any;
}

export function routeMetadata(metadata: any): IRouteMetadata {
    return metadata as IRouteMetadata;
}

export function buildRouteMetadata(metadata: IRouteMetadata): IRouteMetadata {
    return metadata;
}
