import {TimeSpanVO} from "@/core/value-objects/TimeSpanVO";
import {TimezoneReadDTO} from "@/generated/clients";

export class TimezoneBM {
    public constructor(id: number, name: string, utcOffset: TimeSpanVO) {
        this.Id = id;
        this.Name = name;
        this.UTCOffset = utcOffset;
    }

    public Id: number;
    public Name: string;
    public UTCOffset: TimeSpanVO;

    public static createFromDTO(dto: TimezoneReadDTO): TimezoneBM {
        return new TimezoneBM(
            dto.Id!,
            dto.Name!,
            new TimeSpanVO(dto.UTCOffset!)
        );
    }
}
