export class LayoutOptions {
    public constructor(type: LayoutTypeEnum) {
        this.Type = type;
    }

    public Type: LayoutTypeEnum;

    public get isSlim(): boolean {
        return this.Type === LayoutTypeEnum.Slim;
    }
}

export enum LayoutTypeEnum {
    Unset = 0,
    Slim = 1,
    Wide = 2,
    Custom = 3
}
