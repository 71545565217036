import {Guid} from "@/core/types/type-aliases";

export class ConversationParticipantBM {
    public constructor(
        id: Guid,
        firstName: string,
        lastName: string,
        photoUrl: string | null,
        displayOnlyFirstName: boolean) {
        this.Id = id;
        this.FirstName = firstName;
        this.LastName = lastName;
        this.PhotoUrl = photoUrl;
        this.DisplayOnlyFirstName = displayOnlyFirstName;
    }

    public Id: Guid;
    public FirstName: string;
    public LastName: string;
    public PhotoUrl: string | null;
    public DisplayOnlyFirstName: boolean;
}
