import {EmptyControllerActionResult} from "@/generated/clients";

export class OperationResultBM<TResult> {
    public constructor(ok: boolean, errorMessages: string[], result: TResult) {
        this.Ok = ok;
        this.ErrorMessages = errorMessages;
        this.Result = result;
    }

    public Ok: boolean;
    public ErrorMessages: string[];
    public Result: TResult;
}
