import {buildDefault} from "@/core/utils/functional-utils";
import {Guid} from "@/core/types/type-aliases";
import Router from "@/router/router";
import {RouteNames} from "@/router/route-names";
import {Subject} from "rxjs";
import {ConversationMessageBM} from "@/core/models/conversation/ConversationMessageBM";
import {ConversationService} from "@/core/services/conversation/conversation-service";
import {EmptyOperationResultBM} from "@/core/models/common/EmptyOperationResultBM";

export class ConversationManagementService {

    // Event sources:
    private static readonly $onConversationSwitchedEventSource =
        new Subject<OnConversationSwitchedEvent>();

    private static readonly $onIncomingConversationMessagesMarkedAsReadEventSource =
        new Subject<OnIncomingConversationMessagesMarkedAsReadEvent>();

    private static readonly $onConversationMessageSentEventSource =
        new Subject<OnConversationMessageSentEvent>();

    private static source_ActiveConversationId = buildDefault<Guid>();

    public static refresh() {
        this.source_ActiveConversationId = buildDefault<Guid>();
    }

    public static get activeConversationId(): Guid {
        return this.source_ActiveConversationId;
    }

    public static openConversation(conversationId: Guid) {
        if (Router.currentRoute.name === RouteNames.ConversationManager) {
            this.source_ActiveConversationId = conversationId;

            const event = new OnConversationSwitchedEvent(this.source_ActiveConversationId);
            this.$onConversationSwitchedEventSource.next(event);
            return;
        }

        Router.push({
            name: RouteNames.ConversationManager, params: {
                conversationId: conversationId
            }
        });
    }

    public static markIncomingConversationMessagesAsRead(conversationId: Guid, conversationMessageIds: Guid[]) {
        this.$onIncomingConversationMessagesMarkedAsReadEventSource.next(
            new OnIncomingConversationMessagesMarkedAsReadEvent(
                conversationId, conversationMessageIds
            ));
    }

    public static async sendMessage(conversationId: Guid, content: string): Promise<EmptyOperationResultBM> {
        const conversationService = new ConversationService();
        const sendingResult = await conversationService.sendMessage(conversationId, content);
        if (!sendingResult.Ok) {
            return new EmptyOperationResultBM(sendingResult.Ok, sendingResult.ErrorMessages);
        }

        const event = new OnConversationMessageSentEvent(sendingResult.Result);
        this.$onConversationMessageSentEventSource.next(event);
        return EmptyOperationResultBM.success();
    }

    public static subscribeOnConversationSwitching(
        handler: { (options: OnConversationSwitchedEvent): any }) {
        return this.$onConversationSwitchedEventSource.subscribe(handler);
    }

    public static subscribeOnIncomingConversationMessagesMarkedAsRead(
        handler: { (options: OnIncomingConversationMessagesMarkedAsReadEvent): any }) {
        return this.$onIncomingConversationMessagesMarkedAsReadEventSource.subscribe(handler);
    }

    public static subscribeOnConversationMessageSent(
        handler: { (event: OnConversationMessageSentEvent): any }) {
        return this.$onConversationMessageSentEventSource.subscribe(handler);
    }
}

export class OnConversationSwitchedEvent {
    public constructor(conversationId: Guid) {
        this.ConversationId = conversationId;
    }

    public ConversationId: Guid;
}

export class OnIncomingConversationMessagesMarkedAsReadEvent {
    public constructor(conversationId: Guid, conversationMessageIds: Guid[]) {
        this.ConversationId = conversationId;
        this.ConversationMessageIds = conversationMessageIds;
    }

    public ConversationId: Guid;
    public ConversationMessageIds: Guid[];
}

export class OnConversationMessageSentEvent {
    public constructor(conversationMessage: ConversationMessageBM) {
        this.ConversationMessage = conversationMessage;
    }

    public ConversationMessage: ConversationMessageBM;
}
