<template>
    <div @click="onPopupClick" class="new-conversation-message-popup">
        <div class="message">{{ messagePreview }}</div>
        <div class="sender">
            <div class="icon">
                <user-icon v-bind:first-name="participant.FirstName"
                           v-bind:last-name="participant.LastName"
                           v-bind:avatar-url="participant.PhotoUrl"/>
            </div>

            <div class="data">
                <div class="full-name">
                    {{ participant.FirstName }} {{ participant.LastName }}
                </div>
                <div class="date">
                    {{ dateTimeCreated }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import {Component, Prop, Vue} from "vue-property-decorator";
import {BaseComponent} from "@/core/components/BaseComponent";
import {OnConversationUpdatedEvent} from "@/core/services/conversation/conversation-socket";
import Avatar from "@/core/components.business/Avatar.vue";
import {DateHelper} from "@/helpers/date-helper";
import {ConversationManagementService} from "@/views/conversations/services/conversation-management-service";
import {ConversationParticipantBM} from "@/core/models/conversation/ConversationParticipantBM";
import {TemplateDataProvider} from "@/core/providers/template-data-provider";
import {buildDefault, stripHtml} from "@/core/utils/functional-utils";
import {Guid} from "@/core/types/type-aliases";

@Component({
    components: {
        'user-icon': Avatar
    }
})
export default class NewConversationMessagePopup extends BaseComponent {

    // Component properties:
    @Prop({required: true, type: Object}) event!: OnConversationUpdatedEvent;

    private onPopupClick() {
        ConversationManagementService.openConversation(this.event.ConversationMessage.ConversationId);
    }

    private get messagePreview(): string {
        return stripHtml(this.event.ConversationMessage.MessageContent);
    }

    private get dateTimeCreated(): string {
        return DateHelper.ToLocalDateTimeString(this.event.ConversationMessage.DateTimeCreated);
    }

    private get participant(): ConversationParticipantBM {
        if (this.event.ConversationMessage.isSystemMessage) return TemplateDataProvider.buildServiceMessageParticipantData();
        return new ConversationParticipantBM(
            buildDefault<Guid>(),
            this.event.SubjectSummary!.SenderFirstName,
            this.event.SubjectSummary!.SenderLastName,
            this.event.SubjectSummary!.SenderIconUrl,
            false
        );
    }

    public static $liveCreate(parent: Vue, event: OnConversationUpdatedEvent) {
        return parent.$createElement(NewConversationMessagePopup, {
            props: {
                event: event
            }
        })
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/views/conversations/new-conversation-message-popup.scss';
</style>
