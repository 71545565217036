import {LoginDataBM} from "@/views/identity/login/models/LoginDataBM";
import {OperationResultBM} from "@/core/models/common/OperationResultBM";
import {EmptyOperationResultBM} from "@/core/models/common/EmptyOperationResultBM";
import {
    AccountClient, ChangePasswordRequestDTO, LoginRequestDTO, LoginResponseDTO, RegisterRequestDTO, ResetPasswordRequestDTO, SendRestorePasswordApplicationDTO,
    UpdateUserInformationRequestDTO, UserPublicDataResponseDTO,
    UserTypeEnum
} from "@/generated/clients";
import {AxiosInstanceProvider} from "@/core/providers/axios-instance-provider";
import {RegisterBM} from "@/views/identity/registration/models/RegisterBM";
import {BaseUrlProvider} from "@/core/providers/base-url-provider";
import {ChangePasswordActionBM} from "@/views/identity/change-password/models/ChangePasswordBM";
import {SendRestorePasswordApplicationActionBM} from "@/views/identity/restore-password/models/SendRestorePasswordApplicationActionBM";
import {ResetPasswordActionBM} from "@/views/identity/restore-password/models/ResetPasswordActionBM";
import {CurrentUserService} from "@/core/services/current-user-service/current-user-service";
import Router from '../../../router/router';
import {RouteNames} from "@/router/route-names";
import {LogoutOptionsBM} from "@/core/models/common/LogoutOptionsBM";
import {RouterConstants} from "@/router/router-constants";
import {RawLocation} from "vue-router";
import {CurrentUserBM} from "@/core/models/identity/CurrentUserBM";
import {buildDefault} from "@/core/utils/functional-utils";
import {Guid} from "@/core/types/type-aliases";
import {ConversationSocket} from "@/core/services/conversation/conversation-socket";

export class AccountService {
    private clientFactory(): AccountClient {
        return new AccountClient(BaseUrlProvider.getBaseUrl(), AxiosInstanceProvider.Get());
    }

    public async Login(model: LoginDataBM): Promise<OperationResultBM<LoginResponseDTO>> {
        AxiosInstanceProvider.Rebuild();
        const loginRequest: LoginRequestDTO = {
            UserName: model.Login,
            Password: model.Password
        };

        const response = await this.clientFactory().token(loginRequest);
        return new OperationResultBM<LoginResponseDTO>(
            response.Ok !,
            response.ErrorMessages!,
            response.Result!);
    }

    public async Logout(options?: LogoutOptionsBM): Promise<void> {
        const currentUrl = window.location.pathname;

        await ConversationSocket.stop();

        CurrentUserService.logout();
        AxiosInstanceProvider.Rebuild();

        await this.clientFactory().logout();

        const loginRoute: RawLocation = {name: RouteNames.Login};

        if (options?.needsToRestoreUrl) {
            loginRoute.query = {
                [RouterConstants.ReturnUrlQueryParameterName]: currentUrl
            }
        }

        const urlHref = Router.resolve(loginRoute).href;
        window.location.href = urlHref;
    }

    public async ChangePassword(options: ChangePasswordActionBM): Promise<EmptyOperationResultBM> {
        const request: ChangePasswordRequestDTO = {
            OldPassword: options.OldPassword!,
            NewPassword: options.NewPassword!
        };

        const response = await this.clientFactory().changePassword(request);
        return EmptyOperationResultBM.createFromDTO(response);
    }

    public async ResetPassword(options: ResetPasswordActionBM): Promise<EmptyOperationResultBM> {
        const request: ResetPasswordRequestDTO = {
            Email: options.Email,
            ResetToken: options.ResetToken,
            NewPassword: options.NewPassword!
        };

        const response = await this.clientFactory().resetPassword(request);
        return new EmptyOperationResultBM(
            response.Ok as boolean,
            response.ErrorMessages as string[]
        );
    }

    public async SendRestorePasswordApplication(options: SendRestorePasswordApplicationActionBM): Promise<EmptyOperationResultBM> {
        const request: SendRestorePasswordApplicationDTO = {Email: options.Email!};
        await this.clientFactory().sendRestorePasswordApplication(request);
        return new EmptyOperationResultBM(true, []);
    }

    public async GetCurrentUserData(): Promise<OperationResultBM<CurrentUserBM>> {
        const response = await this.clientFactory().getCurrentUserData();
        if (!response.Ok) {
            return new OperationResultBM<CurrentUserBM>(
                response.Ok!,
                response.ErrorMessages!,
                buildDefault<CurrentUserBM>());
        }

        return new OperationResultBM<CurrentUserBM>(
            response.Ok!,
            response.ErrorMessages!,
            CurrentUserBM.createFromDTO(response.Result!)
        );
    }

    public async ReadUserPublicData(userId: Guid): Promise<UserPublicDataResponseDTO> {
        const response = await this.clientFactory().publicData({UserId: userId});
        return response;
    }

    public async UpdateUserInformation(request: UpdateUserInformationRequestDTO): Promise<EmptyOperationResultBM> {
        const response = await this.clientFactory().updateInformation(request);
        return EmptyOperationResultBM.createFromDTO(response);
    }

    public async Register(model: RegisterBM): Promise<OperationResultBM<string>> {
        const registerRequest: RegisterRequestDTO = {
            FirstName: model.FirstName,
            LastName: model.LastName,
            Email: model.Email,
            PhoneNumber: model.PhoneNumber,
            Password: model.Password,
            Type: model.RoleId as any as UserTypeEnum,
            IsPersonalDataProcessingAgreementSigned: model.IsPersonalDataProcessingAgreementSigned,
            ReturnUrl: model.returnUrl
        }

        const response = await this.clientFactory().register(registerRequest);
        return new OperationResultBM<string>(
            response.Ok!,
            response.ErrorMessages!,
            response.Result as any as string);
    }
}
