import {RouteNames} from "@/router/route-names";
import {RouteConfig} from "vue-router";
import {buildRouteMetadata} from "@/router/models/route-metadata";
import {UserTypeEnum} from "@/generated/clients";
import {LayoutNames} from "@/core/constants/LayoutNames";
import {CurrentUserService} from "@/core/services/current-user-service/current-user-service";
import knowledgeBaseRoutes from "@/router/definitions/route-definitions.knowledge-base";
import administrationRoutes from "@/router/definitions/route-definitions.administration";
import cmsRoutes from "@/router/definitions/route-definitions.cms";
import occupancyScheduleRoutes from "@/router/definitions/route-definitions.occupancy-schedule";

const publicRoutes: RouteConfig[] = [
    {
        path: "/",
        name: RouteNames.Home,
        component: () => import(/* webpackChunkName: "home" */ "../views/home-v2/Home.vue"),
    },
    {
        path: "/about",
        name: RouteNames.About,
        component: () => import(/* webpackChunkName: "about" */ "../views/about/About.vue")
    },
    {
        path: "/support",
        name: RouteNames.Help,
        component: () => import(/* webpackChunkName: "support" */ "../views/help/Help.vue")
    },
    {
        path: '/search',
        name: RouteNames.BookingObjectSearch,
        component: () => import(/* webpackChunkName: "search" */ "../views/booking-object-search/BookingObjectSearch.vue")
    },
    {
        path: '/public/user-booking-objects/:publicUserId',
        name: RouteNames.BookingObjectListByUser,
        component: () => import(/* webpackChunkName: "public.user-booking-objects" */ "../views/booking-object-list-by-user/BookingObjectListByUser.vue")
    },
    {
        path: '/objects/:id',
        name: RouteNames.BookingObjectView,
        component: () => import(/* webpackChunkName: "booking-object-view" */ "../views/booking-object-view/BookingObjectView.vue")
    },
    {
        path: '/billing/check-payment',
        name: RouteNames.Billing_CheckPayment,
        component: () => import(/* webpackChunkName: "billing.check-payment" */ "../views/billing/CheckPayment.vue")
    },
    {
        path: '/billing/unsuccessful-payment',
        name: RouteNames.Billing_UnsuccessfulPayment,
        component: () => import(/* webpackChunkName: "billing.unsuccessful-payment" */ "../views/billing/UnsuccessfulPaymentTransaction.vue")
    },
];

const userProfileRoutes: RouteConfig[] = [
    {
        path: "/profile",
        name: RouteNames.MyProfile,
        component: () => import(/* webpackChunkName: "profile" */ "../views/user/profile/UserProfile.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true
        })
    },
    {
        path: "/profile/conversations",
        name: RouteNames.ConversationManager,
        component: () => import(/* webpackChunkName: "profile.conversations" */ "../views/conversations/ConversationManager.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true
        })
    },
];

const userProfileForServiceProviderRoutes: RouteConfig[] = [
    {
        path: '/profile/:userId/objects',
        name: RouteNames.MyBookingObjects,
        component: () => import(/* webpackChunkName: "profile.objects" */ "../views/booking-object-list-own/BookingObjectListOwn.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [
                UserTypeEnum.SystemAdministrator,
                UserTypeEnum.ServiceProvider]
        })
    },
    {
        path: '/profile/bookings-management',
        name: RouteNames.BookingsForServiceProvider,
        component: () => import(/* webpackChunkName: "profile.bookings-management" */ "../views/bookings/BookingsForServiceProvider.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.ServiceProvider]
        })
    },
    {
        path: '/profile/objects/:id/calendars',
        name: RouteNames.BookingObjectManagement_CalendarsManagement,
        component: () => import(/* webpackChunkName: "profile.objects.calendars-management" */ "../views/booking-object-calendar-management/BookingObjectCalendarsManagement.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [
                UserTypeEnum.SystemAdministrator,
                UserTypeEnum.ServiceProvider]
        })
    },
    {
        path: '/profile/objects/wizard/base',
        name: RouteNames.BookingObjectWizard_BaseStep,
        component: () => import(/* webpackChunkName: "profile.objects.wizard.base" */ "../views/booking-object-wizard/BookingObjectWizard_Step0_Category.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.ServiceProvider]
        })
    },

    // Apartments:
    {
        path: '/profile/objects/wizard/apartments/create',
        name: RouteNames.Create_BookingObject_Apartments,
        component: () => import(/* webpackChunkName: "profile.objects.wizard.apartments.create" */ "../views/booking-object-wizard/components.categories/apartments/BookingObjectWizard_Apartment.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.ServiceProvider]
        })
    },
    {
        path: '/profile/objects/wizard/apartments/create/draft',
        name: RouteNames.Create_BookingObject_Apartments_FromDraft,
        component: () => import(/* webpackChunkName: "profile.objects.wizard.apartments.create.draft" */ "../views/booking-object-wizard/components.categories/apartments/BookingObjectWizard_Apartment.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.ServiceProvider]
        })
    },
    {
        path: '/profile/objects/wizard/apartments/update/:id',
        name: RouteNames.Update_BookingObject_Apartments,
        component: () => import(/* webpackChunkName: "profile.objects.wizard.apartments.update" */ "../views/booking-object-wizard/components.categories/apartments/BookingObjectWizard_Apartment.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.ServiceProvider, UserTypeEnum.SystemManager, UserTypeEnum.SystemAdministrator]
        })
    },

    // Hotels:
    {
        path: '/profile/objects/wizard/hotels/create',
        name: RouteNames.Create_BookingObject_Hotels,
        component: () => import(/* webpackChunkName: "profile.objects.wizard.hotels.create" */ "../views/booking-object-wizard/components.categories/hotels/BookingObjectWizard_Hotel.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.ServiceProvider]
        })
    },
    {
        path: '/profile/objects/wizard/hotels/create/draft',
        name: RouteNames.Create_BookingObject_Hotels_FromDraft,
        component: () => import(/* webpackChunkName: "profile.objects.wizard.hotels.create.draft" */ "../views/booking-object-wizard/components.categories/hotels/BookingObjectWizard_Hotel.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.ServiceProvider]
        })
    },
    {
        path: '/profile/objects/wizard/hotels/update/:id',
        name: RouteNames.Update_BookingObject_Hotels,
        component: () => import(/* webpackChunkName: "profile.objects.wizard.hotels.update" */ "../views/booking-object-wizard/components.categories/hotels/BookingObjectWizard_Hotel.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.ServiceProvider, UserTypeEnum.SystemManager, UserTypeEnum.SystemAdministrator]
        })
    },

    // Houses:
    {
        path: '/profile/objects/wizard/houses/create',
        name: RouteNames.Create_BookingObject_Houses,
        component: () => import(/* webpackChunkName: "profile.objects.wizard.houses.create" */ "../views/booking-object-wizard/components.categories/apartments/BookingObjectWizard_Apartment.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.ServiceProvider]
        })
    },
    {
        path: '/profile/objects/wizard/houses/create/draft',
        name: RouteNames.Create_BookingObject_Houses_FromDraft,
        component: () => import(/* webpackChunkName: "profile.objects.wizard.houses.create.draft" */ "../views/booking-object-wizard/components.categories/apartments/BookingObjectWizard_Apartment.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.ServiceProvider]
        })
    },
    {
        path: '/profile/objects/wizard/houses/update/:id',
        name: RouteNames.Update_BookingObject_Houses,
        component: () => import(/* webpackChunkName: "profile.objects.wizard.houses.update" */ "../views/booking-object-wizard/components.categories/apartments/BookingObjectWizard_Apartment.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.ServiceProvider, UserTypeEnum.SystemManager, UserTypeEnum.SystemAdministrator]
        })
    },
];

const userProfileForServiceConsumerRoutes: RouteConfig[] = [
    {
        path: '/profile/bookings',
        name: RouteNames.BookingsForServiceConsumer,
        component: () => import(/* webpackChunkName: "profile.bookings" */ "../views/bookings/BookingsForServiceConsumer.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.ServiceConsumer]
        })
    },
    {
        path: '/profile/favorites',
        name: RouteNames.Favorites,
        component: () => import(/* webpackChunkName: "profile.favorites" */ "../views/favorites/Favorites.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.ServiceConsumer]
        })
    },
    {
        path: '/rating/reviews/booking-object/create-by-client',
        name: RouteNames.Rating_CreateClientBookingObjectReview,
        component: () => import(/* webpackChunkName: "rating.reviews.booking-object.create-by-client" */ "../views/rating/CreateClientBookingObjectReview.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true,
            RequireRoles: [UserTypeEnum.ServiceConsumer]
        })
    }
];

const securityRoutes: RouteConfig[] = [
    {
        path: "/identity/register",
        name: RouteNames.Register,
        component: () => import(/* webpackChunkName: "identity.registration" */ "../views/identity/registration/Register.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UnauthorizedLayout
        }),
        beforeEnter: (to, from, next) => {
            const currentUserService = new CurrentUserService();
            if (currentUserService.isAuthenticated) {
                next({name: RouteNames.MyProfile});
            }

            next();
        }
    },
    {
        path: "/identity/login",
        name: RouteNames.Login,
        component: () => import(/* webpackChunkName: "identity.login" */ "../views/identity/login/LogIn.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UnauthorizedLayout
        }),
        beforeEnter: (to, from, next) => {
            const currentUserService = new CurrentUserService();
            if (currentUserService.isAuthenticated) {
                next({name: RouteNames.MyProfile});
            }

            next();
        }
    },
    {
        path: "/identity/change-password",
        name: RouteNames.ChangePassword,
        component: () => import(/* webpackChunkName: "identity.change-password" */ "../views/identity/change-password/ChangePassword.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UserLayout,
            RequireAuthentication: true
        })
    },
    {
        path: "/identity/restore-password-application",
        name: RouteNames.SendRestorePasswordApplication,
        component: () => import(/* webpackChunkName: "identity.restore-password-application" */ "../views/identity/restore-password/SendRestorePasswordApplication.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UnauthorizedLayout
        }),
        beforeEnter: (to, from, next) => {
            const currentUserService = new CurrentUserService();
            if (currentUserService.isAuthenticated) {
                next({name: RouteNames.MyProfile});
            }

            next();
        }
    },
    {
        path: "/identity/reset-password",
        name: RouteNames.ResetPassword,
        component: () => import(/* webpackChunkName: "identity.reset-password" */ "../views/identity/restore-password/ResetPassword.vue"),
        meta: buildRouteMetadata({
            LayoutComponentName: LayoutNames.UnauthorizedLayout
        }),
        beforeEnter: (to, from, next) => {
            const currentUserService = new CurrentUserService();
            if (currentUserService.isAuthenticated) {
                next({name: RouteNames.MyProfile});
            }

            next();
        }
    },
];

const errorRoutes: RouteConfig[] = [
    {
        path: "/errors/not-found",
        name: RouteNames.PageNotFound,
        component: () => import(/* webpackChunkName: "not-found" */ "../views/$system/NotFound.vue")
    },
    {
        path: "/errors/unauthorized-request",
        name: RouteNames.UnauthorizedRequest,
        component: () => import(/* webpackChunkName: "unauthorized" */ "../views/$system/UnauthorizedRequest.vue"),
    }
];

const serviceRoutes: RouteConfig[] = [
    {
        path: "/s/:key",
        component: () => import(/* webpackChunkName: "short-links-handler" */ "../views/$system/ShortLinkHandler.vue")
    }
];

const routeDefinitions: Array<RouteConfig> = [
    ...publicRoutes,
    ...errorRoutes,
    ...userProfileRoutes,
    ...userProfileForServiceProviderRoutes,
    ...userProfileForServiceConsumerRoutes,
    ...securityRoutes,
    ...administrationRoutes,
    ...occupancyScheduleRoutes,
    ...serviceRoutes,
    ...knowledgeBaseRoutes,
    ...cmsRoutes,

    {
        path: '*',
        beforeEnter: (to, from, next) => next({name: RouteNames.PageNotFound})
    }
];

export default routeDefinitions;
