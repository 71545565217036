import {Defaults} from "@/core/constants/defaults";

export class InformationModalData {
    public constructor(title: string, messages: string[], afterFinishCallback: Function | null = null, allowHideOnlyOnHideButtonClick = false) {
        this.AfterFinishCallback = afterFinishCallback;
        this.Messages = messages;
        this.Title = title;
        this.AllowHideOnlyOnHideButtonClick = allowHideOnlyOnHideButtonClick;
    }

    public AfterFinishCallback: Function | null;
    public Messages: string[];
    public Title: string;
    public AllowHideOnlyOnHideButtonClick = false;

    public static createEmpty(): InformationModalData {
        return new InformationModalData(Defaults.EmptyString, []);
    }
}
