import {ScrollHelper} from "@/helpers/scroll-helper";

export class ScrollTrackingService {
    private static saveScrollTopPosition() {
        sessionStorage.setItem('$scrollTop', ScrollHelper.getScrollPosition().toString());
    }

    public static start() {
        document.addEventListener("scroll", this.saveScrollTopPosition);
        document.getElementsByClassName('base-view')[0].addEventListener('scroll', this.saveScrollTopPosition);
    }

    public static stop() {
        document.removeEventListener("scroll", this.saveScrollTopPosition);
        document.getElementsByClassName('base-view')[0].removeEventListener('scroll', this.saveScrollTopPosition);
    }

    public static getCurrentScrollTop(): number {
        return Number(sessionStorage.getItem('$scrollTop'));
    }
}
