import versionJson from '../../../version.json';
import {CookiePolicyService} from "@/core/services/cookie-policy-service";

export class ApplicationVersionService {
    private readonly Version_StorageKey = 'APP_VERSION';

    private readonly cookiePolicyService = new CookiePolicyService();

    public checkAndApplyChanges() {
        const hasCookiePolicyBeenAccepted = this.cookiePolicyService.isAccepted();

        const userAppVersionSerialized = localStorage.getItem(this.Version_StorageKey);
        if (!userAppVersionSerialized) {
            localStorage.clear();
            localStorage.setItem(this.Version_StorageKey, JSON.stringify(versionJson));

            if (hasCookiePolicyBeenAccepted) this.cookiePolicyService.accept();

            return;
        }

        const userAppVersion = JSON.parse(userAppVersionSerialized) as AppVersionModel;
        const latestAppVersion = versionJson as any as AppVersionModel;

        const needsToClearUserData =
            latestAppVersion.MajorVersion !== userAppVersion.MajorVersion
            || latestAppVersion.MinorVersion !== userAppVersion.MinorVersion;

        if (needsToClearUserData) {
            localStorage.clear();
            if (hasCookiePolicyBeenAccepted) this.cookiePolicyService.accept();
        }

        const needsToUpdateVersion =
            latestAppVersion.MajorVersion !== userAppVersion.MajorVersion
            || latestAppVersion.MinorVersion !== userAppVersion.MinorVersion
            || latestAppVersion.PatchVersion !== userAppVersion.PatchVersion;

        if (needsToUpdateVersion) {
            localStorage.setItem(this.Version_StorageKey, JSON.stringify(versionJson));
        }
    }
}

class AppVersionModel {
    MajorVersion!: number;
    MinorVersion!: number;
    PatchVersion!: number;
}
