import {RouteConfig} from "vue-router";
import {RouteNames} from "@/router/route-names";

const knowledgeBaseRoutes: RouteConfig[] = [
    {
        path: "/knowledge-base/how-to-register",
        name: RouteNames.KnowledgeBase_HowToRegister,
        component: () => import(/* webpackChunkName: "knowledge-base.how-to-register" */ "../../views/knowledge-base/HowToRegister.vue"),
    },
    {
        path: "/knowledge-base/how-to-install-mobile-application",
        name: RouteNames.KnowledgeBase_HowToInstallMobileApplication,
        component: () => import(/* webpackChunkName: "knowledge-base.how-to-install-mobile-application" */ "../../views/knowledge-base/HowToInstallMobileApplication.vue"),
    },
    {
        path: "/knowledge-base/booking-terms",
        name: RouteNames.KnowledgeBase_BookingTerms,
        component: () => import(/* webpackChunkName: "knowledge-base.booking-terms" */ "../../views/knowledge-base/BookingTerms.vue"),
    },
    {
        path: "/knowledge-base/booking-object-registration",
        name: RouteNames.KnowledgeBase_BookingObjectRegistration,
        component: () => import(/* webpackChunkName: "knowledge-base.booking-object-registration" */ "../../views/knowledge-base/BookingObjectRegistration.vue"),
    },
    {
        path: "/knowledge-base/booking-object-price-policies",
        name: RouteNames.KnowledgeBase_BookingObjectPricePolicies,
        component: () => import(/* webpackChunkName: "knowledge-base.price-policies" */ "../../views/knowledge-base/BookingObjectPricePolicies.vue"),
    },
    {
        path: "/knowledge-base/booking-object-occupancy-synchronization",
        name: RouteNames.KnowledgeBase_BookingObjectOccupancySynchronization,
        component: () => import(/* webpackChunkName: "knowledge-base.occupancy-sync" */ "../../views/knowledge-base/BookingObjectOccupancySynchronization.vue"),
    },
];

export default knowledgeBaseRoutes;
