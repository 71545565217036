import {Observable, Subject} from "rxjs";
import {GlobalOverlayOptions} from "@/core/models/system/global-overlay-options";

export class GlobalOverlayService {
    private static $eventSource = new Subject<GlobalOverlayOptions>();
    private static $observer = GlobalOverlayService.$eventSource.asObservable();

    public Loading(text: string | null = null): void {
        GlobalOverlayService.$eventSource.next(new GlobalOverlayOptions(true, text));
    }

    public Completed(): void {
        GlobalOverlayService.$eventSource.next(new GlobalOverlayOptions(false, null));
    }

    public get Observer(): Observable<GlobalOverlayOptions> {
        return GlobalOverlayService.$observer;
    }
}
