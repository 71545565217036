import {Observable, Subject} from "rxjs";
import {LayoutOptions, LayoutTypeEnum} from "@/core/models/system/layout-options";

export class LayoutService {
    private static $eventSource = new Subject<LayoutOptions>();
    private static $observer = LayoutService.$eventSource.asObservable();

    public Slim(): void {
        LayoutService.$eventSource.next(new LayoutOptions(LayoutTypeEnum.Slim));
    }

    public Wide(): void {
        LayoutService.$eventSource.next(new LayoutOptions(LayoutTypeEnum.Wide));
    }

    public get Observer(): Observable<LayoutOptions> {
        return LayoutService.$observer;
    }
}
