import {YandexMetricsParameters} from "@/core/constants/yandex-metrics-parameters";
import {CurrentUserService} from "@/core/services/current-user-service/current-user-service";
import {UserTypeEnum} from "@/generated/clients";

export class YandexMetricService {
    private readonly currentUserService = new CurrentUserService();

    public reachGoal(goal: MetricGoal) {
        try {
            this.reachGoalInternal(goal);
        } catch (err) {
            console.log(err);
        }
    }

    private reachGoalInternal(goal: MetricGoal) {
        let role = YMGoalRoles.AnyRoleNamespace;

        // Если указан список ролей, для которых применима эта цель:
        if (goal.isRoleApplicabilitySet()) {

            const roleDeterminingResult = this.determineRoleForAction(goal);

            // Если не применимо - игнорируем действие:
            if (roleDeterminingResult == null) return;
            role = roleDeterminingResult!;

            // Если подстановка ролей выключена:
            if (!goal.isRoleSubstitutionEnabled) {

                // Указываем в цели роль по умолчанию для этого действия:
                role = goal.defaultRoleIfSubstitutionDisabled!;
            }
        }

        const ymName = `yaCounter${YandexMetricsParameters.CounterId}`;
        const windowTyped = window as any;

        const goalId = YMGoalTemplates.bindRole(goal.template,role);

        windowTyped[ymName].reachGoal(goalId);
    }


    private determineRoleForAction(goal: MetricGoal): string | null {

        // Если пользователь не аутентифицирован и цель не применима в таком случае:
        if (!this.currentUserService.isAuthenticated) {
            if (goal.applicableIfNotAuthenticated) {
                return YMGoalRoles.AnyRoleNamespace;
            }

            // Неприменимо:
            return null;
        }

        // Если пользователь не относится к типу тех, по кому нужно считать аналитику:
        if (this.currentUserService.isAuthenticated && !this.currentUserService.isClient) {

            // Неприменимо:
            return null;
        }

        // Если цель не применима к роли:
        if (!goal.isApplicableForRole(this.currentUserService.profile.ActiveRole)) {

            // Неприменимо:
            return null;
        }

        let role = YMGoalRoles.AnyRoleNamespace;
        switch (this.currentUserService.profile.ActiveRole) {
            case UserTypeEnum.ServiceProvider: {
                role = YMGoalRoles.ProviderRoleNamespace;
                break;
            }
            case UserTypeEnum.ServiceConsumer: {
                role = YMGoalRoles.ConsumerRoleNamespace;
                break;
            }
        }

        return role;
    }
}

export class MetricGoal {
    public constructor(
        applicableRoles: UserTypeEnum[] | null = null,
        applicableIfNotAuthenticated: boolean,
        isRoleSubstitutionEnabled = true,
        defaultRoleIfSubstitutionDisabled: string | null,
        template: string) {
        this.applicableRoles = applicableRoles;
        this.applicableIfNotAuthenticated = applicableIfNotAuthenticated;
        this.isRoleSubstitutionEnabled = isRoleSubstitutionEnabled;
        this.defaultRoleIfSubstitutionDisabled = defaultRoleIfSubstitutionDisabled;
        this.template = template;
    }

    public applicableRoles: UserTypeEnum[] | null;
    public applicableIfNotAuthenticated: boolean;
    public isRoleSubstitutionEnabled: boolean;
    public defaultRoleIfSubstitutionDisabled: string | null;
    public template: string;

    public isRoleApplicabilitySet(): boolean {
        return this.applicableRoles != null && this.applicableRoles.length > 0;
    }

    public isApplicableForRole(role: UserTypeEnum) {
        if (this.applicableRoles == null) return true;
        return this.applicableRoles.includes(role);
    }
}

export class YMGoalRoles {
    public static readonly AnyRoleNamespace = 'any-role';
    public static readonly ConsumerRoleNamespace = 'consumer';
    public static readonly ProviderRoleNamespace = 'provider';
}

export class YMGoalTemplates {
    private static readonly NamespaceSeparator = ':';
    private static readonly RoleNamespaceTemplate = '{ROLE_NAMESPACE_TEMPLATE}';

    private static readonly GoalNamespace = 'b24';

    private static readonly HeaderNamespace = 'header';
    private static readonly MainViewNamespace = 'main-view';
    private static readonly RegistrationAreaNamespace = 'registration';
    private static readonly BookingObjectSearchNamespace = 'booking-object-search'
    private static readonly BookingsNamespace = 'booking';
    private static readonly ConversationsNamespace = 'conversation';
    private static readonly ReviewsNamespace = 'review';

    // Header:
    public static readonly Header_OpensRegistration = this.buildTemplate(
        null,
        true,
        [YMGoalTemplates.HeaderNamespace, 'opens-registration-view']
    );

    public static readonly Header_OpensLogin = this.buildTemplate(
        null,
        true,
        [YMGoalTemplates.HeaderNamespace, 'opens-login-view']
    );

    public static readonly Header_OpensCurrencySelector = this.buildTemplate(
        null,
        true,
        [YMGoalTemplates.HeaderNamespace, 'opens-currency-selector']
    );

    public static readonly Header_CurrencyIsSelected = this.buildTemplate(
        null,
        true,
        [YMGoalTemplates.HeaderNamespace, 'currency-is-selected']
    );

    public static readonly Header_OpensSearchBySearchButton = this.buildTemplate(
        null,
        true,
        [YMGoalTemplates.HeaderNamespace, 'opens-search-view-by-search-button']
    );

    // Main:
    public static readonly MainView_OpensSearchBySearchButton = this.buildTemplate(
        null,
        true,
        [YMGoalTemplates.MainViewNamespace, 'opens-search-view-by-search-button']
    );

    public static readonly MainView_SelectsDates = this.buildTemplate(
        null,
        true,
        [YMGoalTemplates.MainViewNamespace, 'selects-dates']
    );

    public static readonly MainView_DatesAreSelected = this.buildTemplate(
        null,
        true,
        [YMGoalTemplates.MainViewNamespace, 'dates-are-selected']
    );

    public static readonly MainView_OpensBookingObjectFromSlider = this.buildTemplate(
        null,
        true,
        [YMGoalTemplates.MainViewNamespace, 'opens-booking-object-from-slider']
    );

    public static readonly MainView_OpensSearchFromPopularTours = this.buildTemplate(
        null,
        true,
        [YMGoalTemplates.MainViewNamespace, 'opens-search-from-popular-tours']
    );

    // Registration:
    public static readonly Registration_OpensRegistrationView = this.buildTemplate(
        null,
        true,
        [YMGoalTemplates.RegistrationAreaNamespace, 'opens-registration-view']
    );

    public static readonly Registration_HasRegistered = this.buildTemplate(
        null,
        true,
        [YMGoalTemplates.RegistrationAreaNamespace, 'has-registered']
    );

    public static readonly Registration_HasEmailConfirmed = this.buildTemplate(
        null,
        true,
        [YMGoalTemplates.RegistrationAreaNamespace, 'has-email-confirmed']
    );

    public static readonly Registration_HasLoggedInAfterEmailConfirmation = this.buildTemplate(
        [UserTypeEnum.ServiceProvider, UserTypeEnum.ServiceConsumer],
        false,
        [YMGoalTemplates.RegistrationAreaNamespace, 'has-logged-in-after-email-confirmation']
    );

    // Booking object search:
    public static readonly BookingObjectSearch_OpensSearchView = this.buildTemplate(
        [UserTypeEnum.ServiceConsumer],
        true,
        [YMGoalTemplates.BookingObjectSearchNamespace, 'opens-search-view'],
        false,
        YMGoalRoles.ConsumerRoleNamespace
    );

    public static readonly BookingObjectSearch_ClicksOnCheckAvailability = this.buildTemplate(
        [UserTypeEnum.ServiceConsumer],
        true,
        [YMGoalTemplates.BookingObjectSearchNamespace, 'clicks-on-check-availability'],
        false,
        YMGoalRoles.ConsumerRoleNamespace
    );

    public static readonly BookingObjectSearch_SwitchesPages = this.buildTemplate(
        [UserTypeEnum.ServiceConsumer],
        true,
        [YMGoalTemplates.BookingObjectSearchNamespace, 'switches-pages'],
        false,
        YMGoalRoles.ConsumerRoleNamespace
    );

    public static readonly BookingObjectSearch_SelectsDates = this.buildTemplate(
        [UserTypeEnum.ServiceConsumer],
        true,
        [YMGoalTemplates.BookingObjectSearchNamespace, 'selects-dates'],
        false,
        YMGoalRoles.ConsumerRoleNamespace
    );

    public static readonly BookingObjectSearch_DatesAreSelected = this.buildTemplate(
        [UserTypeEnum.ServiceConsumer],
        true,
        [YMGoalTemplates.BookingObjectSearchNamespace, 'dates-are-selected'],
        false,
        YMGoalRoles.ConsumerRoleNamespace
    );

    /*public static readonly BookingObjectSearch_SelectsGuests = this.buildTemplate(
        [UserTypeEnum.ServiceConsumer],
        true,
        YMGoalTemplates.BookingObjectSearchNamespace,
        'selects-guests'
    );

    public static readonly BookingObjectSearch_TypesPlace = this.buildTemplate(
        [UserTypeEnum.ServiceConsumer],
        true,
        YMGoalTemplates.BookingObjectSearchNamespace,
        'types-place'
    );*/

    public static readonly BookingObjectSearch_OpensFilter = this.buildTemplate(
        [UserTypeEnum.ServiceConsumer],
        true,
        [YMGoalTemplates.BookingObjectSearchNamespace, 'opens-filter'],
        false,
        YMGoalRoles.ConsumerRoleNamespace
    );

    public static readonly BookingObjectSearch_FiltersAreSelected = this.buildTemplate(
        [UserTypeEnum.ServiceConsumer],
        true,
        [YMGoalTemplates.BookingObjectSearchNamespace, 'filters-are-selected'],
        false,
        YMGoalRoles.ConsumerRoleNamespace
    );

    public static readonly BookingObjectSearch_ClicksOnSearchButton = this.buildTemplate(
        [UserTypeEnum.ServiceConsumer],
        true,
        [YMGoalTemplates.BookingObjectSearchNamespace, 'clicks-on-search-button'],
        false,
        YMGoalRoles.ConsumerRoleNamespace
    );

    public static readonly BookingObjectSearch_TogglesFavorites = this.buildTemplate(
        [UserTypeEnum.ServiceConsumer],
        false,
        [YMGoalTemplates.BookingObjectSearchNamespace, 'toggles-favorites'],
    );

    // Booking:
    public static readonly Bookings_OpensBookingObject = this.buildTemplate(
        [UserTypeEnum.ServiceConsumer],
        true,
        [YMGoalTemplates.BookingsNamespace, 'opens-booking-object'],
        false,
        YMGoalRoles.ConsumerRoleNamespace
    );

    public static readonly Bookings_ClicksOnPinnedBookingButton = this.buildTemplate(
        [UserTypeEnum.ServiceConsumer],
        true,
        [YMGoalTemplates.BookingsNamespace, 'clicks-on-pinned-booking-button'],
        false,
        YMGoalRoles.ConsumerRoleNamespace
    );

/*    public static readonly Bookings_ChoosesPaymentMethod = this.buildTemplate(
        [UserTypeEnum.ServiceConsumer],
        true,
        YMGoalTemplates.BookingsNamespace,
        'chooses-payment-method'
    );*/

    public static readonly Bookings_Books = this.buildTemplate(
        [UserTypeEnum.ServiceConsumer],
        true,
        [YMGoalTemplates.BookingsNamespace, 'books'],
        false,
        YMGoalRoles.ConsumerRoleNamespace
    );

    public static readonly Bookings_OpensDetailedCalculation = this.buildTemplate(
        [UserTypeEnum.ServiceConsumer],
        true,
        [YMGoalTemplates.BookingsNamespace, 'opens-detailed-calculation'],
        false,
        YMGoalRoles.ConsumerRoleNamespace
    );

    public static readonly Bookings_RedirectedToLoginPageIfNotAuthenticated = this.buildTemplate(
        [UserTypeEnum.ServiceConsumer],
        true,
        [YMGoalTemplates.BookingsNamespace, 'redirected-to-login-page-if-not-authenticated'],
        false,
        YMGoalRoles.ConsumerRoleNamespace
    );

    public static readonly Bookings_RedirectedToPaymentSystem = this.buildTemplate(
        [UserTypeEnum.ServiceConsumer],
        false,
        [YMGoalTemplates.BookingsNamespace, 'redirected-to-payment-system']
    );

    public static readonly Bookings_BookingHasPaid = this.buildTemplate(
        [UserTypeEnum.ServiceConsumer],
        true,
        [YMGoalTemplates.BookingsNamespace, 'booking-has-paid'],
        false,
        YMGoalRoles.ConsumerRoleNamespace
    );

    // Conversations:
    public static readonly Conversations_OpensManager = this.buildTemplate(
        [UserTypeEnum.ServiceConsumer],
        false,
        [YMGoalTemplates.ConversationsNamespace, 'opens-manager']
    );

    public static readonly Conversations_OpensSpecificConversation = this.buildTemplate(
        [UserTypeEnum.ServiceConsumer],
        false,
        [YMGoalTemplates.ConversationsNamespace, 'opens-specific-conversation']
    );

    public static readonly Conversations_SendsMessage = this.buildTemplate(
        [UserTypeEnum.ServiceConsumer],
        false,
        [YMGoalTemplates.ConversationsNamespace, 'sends-message']
    );

    // Reviews:
    public static readonly Reviews_OpensLeaveReviewView = this.buildTemplate(
        [UserTypeEnum.ServiceConsumer],
        false,
        [YMGoalTemplates.ReviewsNamespace, 'opens-left-review-view']
    );

    public static readonly Reviews_ReviewIsLeft = this.buildTemplate(
        [UserTypeEnum.ServiceConsumer],
        false,
        [YMGoalTemplates.ReviewsNamespace, 'review-is-left']
    );

    public static buildTemplate(
        applicableRoles: UserTypeEnum[] | null,
        applicableIfNotAuthenticated: boolean,
        namespaces: string[],
        isRoleSubstitutionEnabled = true,
        defaultRoleIfSubstitutionDisabled: string | null = null) {
        const template = [this.GoalNamespace, this.RoleNamespaceTemplate].concat(namespaces).join(this.NamespaceSeparator);
        return new MetricGoal(
            applicableRoles,
            applicableIfNotAuthenticated,
            isRoleSubstitutionEnabled,
            defaultRoleIfSubstitutionDisabled,
            template);
    }

    public static bindRole(template: string, role: string) {
        return template.replace(this.RoleNamespaceTemplate, role);
    }
}
