import {ApplicationVersionService} from "@/core/services/application-version-service";
import {CurrentUserService} from "@/core/services/current-user-service/current-user-service";
import {AxiosInstanceProvider} from "@/core/providers/axios-instance-provider";

export function initializeApplication() {
    const appVersionService = new ApplicationVersionService();
    appVersionService.checkAndApplyChanges();

    // Initialize user data, if it is existed, before starting of vue:
    CurrentUserService.initialize();
    AxiosInstanceProvider.Rebuild();
}
